import React from "react";
import "./SearchInput.scss";

function SearchInput({ value, onChange }) {
  const updateValue = e => onChange(e.target.value);
  const clearValue = e => onChange('');
  return (
    <div className="search-input-wrapper">
      <input className="search-input" placeholder="Realtor name" value={value || ""} onChange={updateValue} />
      <svg viewBox="0 0 24 24" className="search-icon">
        <circle cx="11" cy="11" r="8" />
        <line x1="21" y1="21" x2="16.65" y2="16.65" />
      </svg>
      { value &&
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000" className="clear-search-icon" onClick={clearValue}>
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
        </svg> }
    </div>
  );
}

export default SearchInput;
