import React from "react";
import { Router } from "@reach/router";
import { BreakpointProvider } from "../hooks/useBreakpoints";
import useDetailPageReviewsEnabled from "../hooks/useDetailPageReviewsEnabled";
import Home from "../components/Home";
import Search from "../components/Search";
import AddReview from "../components/AddReview";
import AgentDetails from "../components/AgentDetails";
import PrivacyPolicy from "../components/PrivacyPolicy";
import "./App.scss";

const mediaQueries = { isMobile: "(max-width: 680px)" };

function App() {
  useDetailPageReviewsEnabled();
  return (
    <BreakpointProvider queries={mediaQueries}>
      <Router>
        <Home path="/" />
        <Search path="/search" />
        <AddReview path="/addreview" />
        <AgentDetails path="/agent/:agentId" />
        <PrivacyPolicy path="/privacy" />
      </Router>
    </BreakpointProvider>
  );
}

export default App;
