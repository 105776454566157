import React from "react";
import MediaQuery from 'react-responsive'
import { navigate } from "@reach/router";
import SearchInput from "../SearchInput/SearchInput";
import logo from "../../assets/logo.png";
import "./Header.scss";

const goHome = () => navigate("/");

function Header({ showHomeButton, showSearchButton, showSearchBar, searchValue, onSearchChange }) {
  const searchbar = showSearchBar ? <SearchInput value={searchValue} onChange={onSearchChange} /> : null;
  return (
    <div className={`header ${showSearchBar ? "" : " no-search"}`}>
      {showHomeButton &&
        <MediaQuery maxWidth={680}>
          <div className="header-button home-button" onClick={goHome} alt="link to home">
            <svg viewBox="0 0 24 24" className="home-icon">
              <path d="M10,20V14H14V20H19V12H22L12,3L2,12H5V20H10Z" />
            </svg>
          </div>
        </MediaQuery>}
      <img src={logo} className="logo" onClick={goHome} alt="The Agent Ratings logo" />
      {showSearchButton &&
        <MediaQuery maxWidth={680}>
          <div className="header-button search-button" onClick={goHome} alt="link to search">
            <svg viewBox="0 0 24 24" className="search-icon">
              <circle cx="11" cy="11" r="8" />
              <line x1="24" y1="24" x2="16.65" y2="16.65" />
            </svg>
          </div>
        </MediaQuery>}
        {showSearchButton &&
          <MediaQuery minWidth={681}>
            <div className="search-button-wrapper" onClick={goHome}>
              Search
            </div>
          </MediaQuery>}
      {searchbar}
    </div>
  );
}

export default Header;
