import { useState, useEffect } from "react";
import axios from "axios";

let cache;
const resultsPerLoad = 10;
let resultsPage = -1;

export default function useTopAgents(requestedResultsPage) {
  const [loading, setLoading] = useState(false);
  const [topAgents, setTopAgents] = useState([]);

  useEffect(() => {
    if (resultsPage !== requestedResultsPage) {
      (async () => {
        try {
          setLoading(true);
          let resultsOffset = requestedResultsPage * resultsPerLoad;
          const { data } = await axios.get(`https://1pi0vb2rii.execute-api.us-east-1.amazonaws.com/dev/v1/agents?limit=${resultsPerLoad}&offset=${resultsOffset}`);
          const additionalTopAgents = data.map(d => ({
            ...d,
            reviewDate: new Date(d.reviewDate),
            averageRating: Number(d.averageRating)
          }));
          let totalTopAgents = [...topAgents, ...additionalTopAgents]
          setTopAgents(totalTopAgents);
          setLoading(false);
          cache = totalTopAgents;
          resultsOffset += resultsPerLoad;
          resultsPage = requestedResultsPage;
        } catch (err) {
          setLoading(false);
          console.warn(err);
        }
      })();
    } else {
      setLoading(false);
      setTopAgents(cache);
    }
  }, [requestedResultsPage]);

  return { loading, topRatedAgents: topAgents };
}
