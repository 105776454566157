import React, { useState } from "react";
import { createPortal } from "react-dom";
import axios from "axios";
import usePortal from "../../hooks/usePortal";
import FullScreenModal from "../FullScreenModal";
import FormInput from "../FormInput";
import "./ThankYouModal.scss";

function ThankYouModal({ enteredGiveaway, close }) {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");

  const submitEnabled = !!email && !!firstName;

  const submit = async () => {
    if (submitEnabled) {
      await axios.post("https://1pi0vb2rii.execute-api.us-east-1.amazonaws.com/dev/v1/giveaway", {
        email,
        firstName
      });
      close();
    }
  };

  const content = enteredGiveaway ? (
    <div className="thank-you-text">
      We appreciate you leaving a rating - giveaway winner will be announced May 31, 2021!
    </div>
  ) : (
    <div className="giveaway-entry-wrapper">
      <div className="thank-you-text">Thanks for submitting a rating - leave your info here to enter our giveaway!</div>
      <div className="info">
        <FormInput label="Your Name" value={firstName} onChange={setFirstName} placeholder="Jane Doe" />
        <FormInput label="Your Email Address" value={email} onChange={setEmail} placeholder="your@email.here" />
      </div>
    </div>
  );

  const buttons = enteredGiveaway ? (
    <div className="modal-button" onClick={close}>
      CLOSE
    </div>
  ) : (
    <>
      <div className="modal-button cancel" onClick={close}>
        I'D RATHER NOT
      </div>
      <div className={`modal-button${submitEnabled ? "" : " disabled"}`} onClick={submit}>
        SUBMIT
      </div>
    </>
  );

  const sizeStyle = enteredGiveaway ? { maxHeight: 310, maxWidth: 550 } : null;

  return (
    <FullScreenModal isOpen={true} close={close}>
      <div className="new-task-modal" style={sizeStyle}>
        <div className="modal-contents">
          <div className="headline">Thank You!</div>
          <div className="content">{content}</div>
        </div>
        <div className="bottom-buttons">{buttons}</div>
      </div>
    </FullScreenModal>
  );
}

const ThankYouModalContainer = ({ isOpen, ...props }) => {
  const portalTarget = usePortal("thank-you-modal-container");
  const contents = isOpen ? <ThankYouModal {...props} /> : null;
  return createPortal(contents, portalTarget);
};

export default ThankYouModalContainer;
