import React, { useState, useEffect } from "react";
import { Link, navigate } from "@reach/router";
import * as queryString from "query-string";
import useAnalytic from "../../hooks/useAnalytic";
import useAgent from "../../hooks/useAgent";
import useDetailPageReviewsEnabled from "../../hooks/useDetailPageReviewsEnabled";
import Header from "../Header";
import Footer from "../Footer";
import "./AgentDetails.scss";
import Div100vh from 'react-div-100vh';

function Review({ rating, review, reviewDate }) {
  return (
    <div className="review-wrapper">
      <div className="rating-wrapper">
        <div className="rating">
          {(rating || 0).toFixed(1).replace(".0", "")} / 10
        </div>
        <div className="review-date">
          {reviewDate ? `${reviewDate.toLocaleDateString('en-US')}` : ""}
        </div>
      </div>
      <div className="review-text">{review ? `"${review}"` : ""}</div>
    </div>
  );
}

function Loading() {
  return (
    <div className="add-review">
      <Header showHomeButton={true} showSearchButton={true}/>
      <div className="loader" />
    </div>
  );
}

function AgentDetails({ location, agentId }) {
  if (!agentId) navigate("/");

  useAnalytic("Agent Details Page Visit");

  const [refreshId, setRefreshId] = useState();
  useEffect(() => {
    const { refresh } = queryString.parse(location.search);
    if (refresh === "true") setRefreshId(String(Math.random()).replace("0.", ""));
  }, []); //eslint-disable-line

  const agent = useAgent(agentId, refreshId);
  const reviewsEnabled = useDetailPageReviewsEnabled();
  const { profileImageURL, firstName, lastName, averageRating: rating, state, reviews = [] } = agent || {};

  const profileImage = profileImageURL ? (
    <img src={profileImageURL} alt="Agent profile" />
  ) : (
    <div className="profile-image-placeholder">
      <svg viewBox="0 0 24 24" className="user-icon">
        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
        <circle cx="12" cy="7" r="4" />
      </svg>
    </div>
  );

  const agentInfo = agent.id ? (
    <div className="agent-info">
      <div className="profile-image">{profileImage}</div>
      <div className="agent-info-wrapper">
        <div className="info-row">
          <div className="info-wrapper">
            <div className="name">
              {firstName} {lastName}
            </div>
            <div className="rest">
              <div className="rating-wrapper">
                { rating
                  ? `${(rating || 0).toFixed(1).replace(".0", "")} / 10`
                  : "No rating yet" }
              </div>
              <div className="state">
                <svg viewBox="0 0 24 24" className="pin-icon">
                  <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
                  <circle cx="12" cy="10" r="3" />
                </svg>
                <span>{state}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rate-agent-wrapper">
        <div className="rate-agent-title">Worked with {firstName} before?</div>
        <Link className="cta-button" to={`/addreview?agentId=${agentId}`}>
          RATE YOUR EXPERIENCE
        </Link>
      </div>
    </div>
  ) : null;

  const reviewRows = reviews.map(review => <Review {...review} key={review.id} />);
  const reviewsSection = reviewsEnabled ? (
    <>
      { rating && <div className="content-title">Reviews</div> }
      <div className="reviews-wrapper">{reviewRows}</div>
    </>
  ) : null;

  if (agentId && !agent?.id) return <Loading />;

  return (
    <Div100vh>
      <div className="agent-details">
        <Header showHomeButton={true} showSearchButton={true} />
        <div className="content-wrapper">
          {agentInfo}
          {reviewsSection}
        </div>
        <Footer />
      </div>
    </Div100vh>
  );
}

export default AgentDetails;
