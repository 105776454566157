import { useState, useEffect } from "react";
import axios from "axios";

const storageKey = "theagentreviews:detailPageReviewsEnabled";

localStorage.removeItem(storageKey); // Turn this line off if you want to preserve values between sessions

let inFlight = false;
let detailPageReviewsEnabled = true;
let observers = [];

const init = async () => {
  try {
    try {
      detailPageReviewsEnabled = JSON.parse(localStorage.getItem(storageKey) || "null");
    } catch (err) {
      detailPageReviewsEnabled = null;
    }

    if (detailPageReviewsEnabled == null) {
      inFlight = true;
      const { data: { detailPageReviewsEnabled: shouldBeEnabled } = {} } = await axios.get(
        `https://1pi0vb2rii.execute-api.us-east-1.amazonaws.com/dev/v1/config/showreviews`
      );
      localStorage.setItem(storageKey, JSON.stringify(shouldBeEnabled));
      inFlight = false;
      detailPageReviewsEnabled = shouldBeEnabled;
      observers.forEach(update => update(detailPageReviewsEnabled));
    }
  } catch (err) {
    inFlight = false;
    console.warn(err);
  }
};

export default function useDetailPageReviewsEnabled() {
  const [enabled, setEnabled] = useState(detailPageReviewsEnabled);

  useEffect(() => {
    observers.push(setEnabled);
    setEnabled(detailPageReviewsEnabled);

    return () => {
      observers = observers.filter(update => update !== setEnabled);
    };
  }, [enabled]);

  return enabled;
}

init();
