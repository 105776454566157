import React, { useRef } from "react";
import "./FullScreenModal.scss";

function FullScreenModal({ close, loading, isOpen, children }) {
  const backgroundRef = useRef();

  // const backgroundClose = e => {
  //   if (e.target === backgroundRef.current) {
  //     close();
  //   }
  // };

  return (
    <div className={`fullscreen-modal${isOpen ? " open" : ""}`} ref={backgroundRef}>
      {children}
    </div>
  );
}

export default FullScreenModal;
