import React, { useState } from "react";
import { Link } from "@reach/router";
import useAnalytic from "../../hooks/useAnalytic";
import useTopRatedAgents from "../../hooks/useTopRatedAgents";
import useSearch from "../../hooks/useSearch";
import Banner from "../Banner";
import Header from "../Header";
import SearchInput from "../SearchInput/SearchInput";
import AgentResult from "../AgentResult";
import Footer from "../Footer";
import "./Home.scss";

function Home() {
  useAnalytic("Home Page Visit");

  const [topAgentsResultsPage, setTopAgentsResultsPage] = useState(0);
  const { loading: isTopAgentsLoading, topRatedAgents } = useTopRatedAgents(topAgentsResultsPage);
  const [search, setSearch] = useState("");
  const [showBanner, setShowBanner] = useState(true);

  const [searchResultsPage, setSearchResultsPage] = useState(0);
  const { loading: isSearchLoading, results: searchResults, totalResultsCount: totalSearchResultsCount } = useSearch(searchResultsPage, search);
  const totalResults = searchResults ? searchResults.length : 0;

  const agentResults = totalResults ? (
    (searchResults || []).map(agent => <AgentResult agent={agent} key={agent.id} />)
  ) : (
        (search === '') &&
    (topRatedAgents || []).map(agent => <AgentResult agent={agent} key={agent.id} />)
  );

  const handleLoadMore = () => {
    if (totalResults) {
      setSearchResultsPage(searchResultsPage + 1);
    }
    else {
      setTopAgentsResultsPage(topAgentsResultsPage + 1);
    }
  };

  const handleSearchChange = (query) => {
    setSearchResultsPage(0);
    setSearch(query);
  };

  const handleHideClicked = () => {
    setShowBanner(false);
  }

  const loader = (isTopAgentsLoading || isSearchLoading) ? (
    <div className="loader" />
  ) :
    ((totalResults && (totalResults < totalSearchResultsCount)) || !(search && search.length > 0)) ?
      (
        <div className="cta-row">
          <div className="cta-button load-more-button" label="Load More" onClick={() => handleLoadMore()}>
            LOAD MORE
          </div>
        </div>
      ) :
      (
        <div/>
      )

  const resultsTitle = totalResults
        ? `Results (${totalSearchResultsCount})`
        : (isSearchLoading)
            ? ""
            : (search !== '')
                ? `No agents with "${search}" in their name`
                : "Top Rated Realtors in Utah";

  return (
    <div className="home">
      <Header showSearchBar={false} searchValue={search} onSearchChange={handleSearchChange} />
      { showBanner ? <Banner
          text="Leave a review to enter our giveaway! Start by searching for your realtor below."
          onHideClicked={handleHideClicked}/> : null }
      <div className="hero">
        <div className="hero-title">Bringing Transparency to American Real Estate</div>
        <div className="subtitle">Find a realtor</div>
        <div className="cta-row">
          <SearchInput value={search} onChange={handleSearchChange} />
        </div>
      </div>
      <div className="content-wrapper">
        <div className="content-title">{resultsTitle}</div>
        <div className="agent-results">{agentResults}</div>
        {loader}
      </div>
      <Footer />
    </div>
  );
}

export default Home;
