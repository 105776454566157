import { useState, useEffect } from "react";
import axios from "axios";

let inFlight = {};
let latestQuery;
let cache = {};
let countCache = {};
const resultsPerLoad = 10;

export default function useAgent(requestedResultsPage, rawQuery) {
  const [results, setResults] = useState([]);
  const [totalResultsCount, setTotalResultsCount] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const query = rawQuery.trim();
    if (!query) {
      latestQuery = null;
      setResults([]);
      setLoading(false);
      return;
    }

    if (inFlight[query]) {
      latestQuery = query;
      setResults(cache[query]);
      setTotalResultsCount(countCache[query]);
      return;
    }

    (async () => {
      try {
        latestQuery = query;
        setLoading(true);

        inFlight[query] = true;

        var totalResultsCount = await fetchAndCacheAgentCount(query);
        var totalAgents = await fetchAndCacheAgents(query);

        inFlight[query] = false;

        if (query === latestQuery) {
          setResults(totalAgents);
          setTotalResultsCount(totalResultsCount);
          setLoading(false);
        }
      } catch (err) {
        console.warn(err);
        inFlight[query] = false;
        setLoading(false);
      }
    })();
  }, [rawQuery, requestedResultsPage]);

  const fetchAndCacheAgents = async (q) => {
    var offset = (cache[q]) ? cache[q].length : 0;
    const { data: agentResponse } = await axios.get(
      `https://1pi0vb2rii.execute-api.us-east-1.amazonaws.com/dev/v1/agents?q=${q}&limit=${resultsPerLoad}&offset=${offset}`
    );
    const additionalAgents = agentResponse.map(a => ({
      ...a,
      averageRating: Number(a.averageRating),
      reviewDate: new Date(a.reviewDate)
    }));

    var cachedAgents = (cache[q]) ? cache[q] : [];
    var totalAgents = [...cachedAgents, ...additionalAgents];
    cache[q] = totalAgents;
    return totalAgents;
  };

  const fetchAndCacheAgentCount = async (q) => {
    if (countCache[q]) {
      return countCache[q];
    }
    else {
      const { data: countResponse } = await axios.get(
        `https://1pi0vb2rii.execute-api.us-east-1.amazonaws.com/dev/v1/agents/count?q=${q}`
      );
      countCache[q] = countResponse.count;
      return countResponse.count;
    }
  };

  return { loading, results, totalResultsCount };
}
