import React from "react";
import { Link } from "@reach/router";
import "./Footer.scss";

function Footer() {
  return (
    <div className="footer">
      <div className="copyright">&copy; {new Date().getFullYear()} The Agent Ratings, LLC</div>
      <div className="links">
        <Link className="link" to="/privacy">
          Privacy Policy
        </Link>
        {/* <div className="link">Contact Us</div> */}
      </div>
    </div>
  );
}

export default Footer;
