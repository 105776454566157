import React, { useEffect, useState } from "react";
import { Link, navigate } from "@reach/router";
import * as queryString from "query-string";
import axios from "axios";
import Select from "react-select";
import { AsYouType, parsePhoneNumber } from "libphonenumber-js";
import useAnalytic from "../../hooks/useAnalytic";
import useAgent from "../../hooks/useAgent";
import Header from "../Header";
import FormInput from "../FormInput";
import Footer from "../Footer";
import ThankYouModal from "../ThankYouModal";
import "./AddReview.scss";

export const STATES = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WI",
  "WV",
  "WY"
];

const formatRawPhone = raw => {
  const digitsOnly = raw.replace(/^(\+)?1/, "").replace(/[^0-9]/g, "");
  if (digitsOnly.length < 11) {
    if (!isNaN(Number((raw || "").slice(-1)))) {
      const phoneFormatter = new AsYouType("US");
      return phoneFormatter.input(raw);
    }
    return raw;
  } else {
    return raw.slice(0, raw.length - 1);
  }
};

const selectStyles = {
  control: provided => ({
    ...provided,
    border: "1px solid var(--SoothingBreeze)",
    borderRadius: 6
  }),
  placeholder: provided => ({
    ...provided,
    color: "var(--SoothingBreeze)"
  })
};

const ratingOptions = [...new Array(10)].map((_, i) => 10 - i).map(x => ({ value: x, label: x }));
const RatingSelect = props => <Select options={ratingOptions} styles={selectStyles} {...props} />;

const stateOptions = STATES.map(x => ({ value: x, label: x }));
const StateSelect = props => <Select options={stateOptions} styles={selectStyles} {...props} />;

function Loading() {
  return (
    <div className="add-review">
      <Header showHomeButton={true} showSearchButton={true}/>
      <div className="loader" />
    </div>
  );
}

function AddReview({ location }) {
  useAnalytic("Add Review Page Visit");

  const [prefilledSettled, setPrefilledSettled] = useState(false);
  const [agentId, setAgentId] = useState();
  useEffect(() => {
    const { agentId: qpAgentId, agentid } = queryString.parse(location.search);
    if (qpAgentId || agentid) setAgentId(qpAgentId || agentid);
    setPrefilledSettled(true);
  }, [location.search]);

  const [fields, setFields] = useState({});
  const setField = field => value => setFields({ ...fields, [field]: value });
  const updatePhone = raw => {
    const phone = formatRawPhone(raw);
    setField("phone")(phone);
  };

  const [agentPrefilled, setAgentPrefilled] = useState(false);
  const agent = useAgent(agentId);
  useEffect(() => {
    if (agent.id) {
      setFields({
        id: agent.id,
        firstName: agent.firstName,
        lastName: agent.lastName,
        email: agent.email,
        phone: formatRawPhone(agent.phoneNumber)
      });
      setAgentPrefilled(true);
    }
  }, [agent]);

  const [existingAgentDetermined, setExistingAgentDetermined] = useState(false);
  const [existingAgent, setExistingAgent] = useState(false);
  useEffect(() => {
    (async () => {

      // TODO: First check the id, then check the phone number
        const { data: fetchedAgentById } = await axios.post(
          "https://1pi0vb2rii.execute-api.us-east-1.amazonaws.com/dev/v1/agents/idcheck",
          {
            id: fields.id
          }
        );
        if (fetchedAgentById) {
          setExistingAgent(fetchedAgentById);
          setExistingAgentDetermined(true);
          return;
        }

      let validPhoneNumber = false;
      try {
        validPhoneNumber = parsePhoneNumber(fields.phone || "", "US").isValid();
      } catch {}
    })();
  }, [fields.phone]);

  const [thankYouModalOpen, setThankYouModalOpen] = useState(false);
  const openThankYouModal = () => setThankYouModalOpen(true);
  const closeThankYouModal = () => {
    setThankYouModalOpen(false);

    if (agentId && agentPrefilled) navigate(`/agent/${agentId}?refresh=true`);
    else navigate("/");
  };

  const checkSubmitEnabled = () => {
    return (
      !!fields.firstName &&
      !!fields.lastName &&
      !!(fields.rating || {}).value &&
      !!fields.state &&
      !!fields.review
    );
  };
  const submitEnabled = checkSubmitEnabled();

  const [submitting, setSubmitting] = useState(false);
  const submitReview = async () => {
    if (submitEnabled) {
      setSubmitting(true);
      const review = {
        firstName: fields.firstName,
        lastName: fields.lastName,
        phoneNumber: fields.phone,
        email: fields.agentEmail,
        state: (fields.state || {}).value,
        city: fields.city,
        zip: fields.zip,
        review: fields.review,
        rating: (fields.rating || {}).value,
        userFirstName: fields.userFirstName,
        userEmail: fields.userEmail,
        agentId: fields.id,
      };
      try {
        await axios.post("https://1pi0vb2rii.execute-api.us-east-1.amazonaws.com/dev/v1/reviews", review);
        setSubmitting(false);
        openThankYouModal();
      } catch (err) {
        setSubmitting(false);
        console.warn(err);
      }
    }
  };

  const loadingOverlay = submitting ? (
    <div className="loading-overlay">
      <div class="loader" />
    </div>
  ) : null;

  const title = agentPrefilled
    ? `Tell us about your experience working with ${agent?.firstName}!`
    : "Let's get some info first";

  const agentPersonalInfoRows = agentPrefilled ? null : (
    <>
      <div className="info-row">
        <FormInput
          label="Agent's first name"
          value={fields.firstName}
          onChange={setField("firstName")}
          placeholder="Jane"
          disabled={agentPrefilled}
          required={true}
        />
        <FormInput
          label="Agent's last name"
          value={fields.lastName}
          onChange={setField("lastName")}
          placeholder="Doe"
          disabled={agentPrefilled}
        />
      </div>
      <div className="info-row">
        <FormInput
          label="Agent's phone number"
          value={fields.phone}
          onChange={updatePhone}
          placeholder="555-555-5555"
          disabled={agentPrefilled}
          optional={true}
        />
        <FormInput
          label="Agent's email"
          value={fields.agentEmail}
          onChange={setField("agentEmail")}
          placeholder="janedoe@realestate.com"
          disabled={agentPrefilled}
          optional={true}
        />
      </div>
    </>
  );

  const reviewTitle = agentPrefilled ? "Let's hear your thoughts" : "...and now let's hear what you have to say!";

  if (!prefilledSettled || (agentId && !agent?.id)) return <Loading />;

  return (
    <div className="add-review">
      <Header showHomeButton={true} showSearchButton={true}/>
      <div className="content-wrapper">
        <div className="content-title info">{title}</div>
        <div className="info">
          {agentPersonalInfoRows}
          <div className="info-row rating">
            <div className="form-input-label">Rating (1-10)</div>
            <RatingSelect className="rating-input" value={fields.rating} onChange={setField("rating")} />
          </div>
          <div className="info-row">
            <div className="state-select-wrapper">
              <div className="form-input-label">State</div>
              <StateSelect className="state-select" value={fields.state} onChange={setField("state")} />
            </div>
            {/* <FormInput label="Transaction State" value={fields.state} onChange={setField("state")} placeholder="UT" /> */}
            <FormInput
              label="Transaction city"
              value={fields.city}
              onChange={setField("city")}
              placeholder="Salt Lake City"
              optional={true}
            />
            <FormInput
              label="Transaction zip code"
              value={fields.zip}
              onChange={setField("zip")}
              placeholder="84106"
              optional={true}
            />
          </div>
        </div>
        <div className="content-title info">{reviewTitle}</div>
        <div className="info">
          <FormInput
            type="textarea"
            value={fields.review}
            onChange={setField("review")}
            placeholder="Write your review here"
          />
        </div>
        <div className="info">
          <div className={`cta-button${submitEnabled ? "" : " disabled"}`} onClick={submitReview}>
            SUBMIT
          </div>
        </div>
      </div>
      <Footer />
      <ThankYouModal isOpen={thankYouModalOpen} enteredGiveaway={false} close={closeThankYouModal} />
      {loadingOverlay}
    </div>
  );
}

export default AddReview;
