import React from "react";
import { navigate } from "@reach/router";
import "./AgentResult.scss";

const goToAgentPage = (agent = {}) => () => navigate(`/agent/${agent.id}`);

function AgentResult({ agent }) {
  const { profileImageURL, firstName, lastName, averageRating: rating, state, review } = agent || {};

  const profileImage = profileImageURL ? (
    <img src={profileImageURL} alt="Agent's profile" />
  ) : (
    <div className="profile-image-placeholder">
      <svg viewBox="0 0 24 24" className="user-icon">
        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
        <circle cx="12" cy="7" r="4" />
      </svg>
    </div>
  );

  let ratingColor = 'grey';
  if (rating > 0 && rating < 5) {
    ratingColor = 'red';
  }
  if (rating >= 5 && rating < 7) {
    ratingColor = 'orange';
  }
  if (rating >= 7 && rating < 8) {
    ratingColor = 'yellow';
  }
  if (rating >= 8 && rating < 9) {
    ratingColor = 'yellow-green';
  }
  if (rating >= 9) {
    ratingColor = 'green';
  }

  const ratingBox = rating > 0 ? (
    <div className="rating-wrapper">
      <div className="rating-value" id={`${ratingColor}-rating`}>
        <div className="rating-numerator">
          { rating
            ? `${(rating || 0).toFixed(1).replace(".0", "")}`
            : "" }
        </div>
        <div className="rating-slash">
        /
        </div>
        <div className="rating-denominator">
        10
        </div>
      </div>
    </div>
  ) : (
    <div/>
  );

  return (agent || {}).id ? (
    <div className="agent-result" onClick={goToAgentPage(agent)}>
      <div className="profile-image">{profileImage}</div>
      <div className="agent-info-wrapper">
        <div className="agent-summary-info">
          <div className="main-details">
            <div className="name">
              {firstName} {lastName}
            </div>
            <div className="state">
              { review &&
                <svg viewBox="0 0 24 24" className="pin-icon">
                  <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
                  <circle cx="12" cy="10" r="3" />
                </svg>
              }
              <span>{state}</span>
            </div>
          </div>
        { ratingBox }
        </div>
        <div className="reviews">{review ? `"${review}"` : ""}</div>
      </div>
    </div>
  ) : null;
}

export default AgentResult;
