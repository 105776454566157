import React, { useState } from "react";
import useAnalytic from "../../hooks/useAnalytic";
import useSearch from "../../hooks/useSearch";
import AgentResult from "../AgentResult";
import Header from "../Header";
import SearchInput from "../SearchInput";
import Footer from "../Footer";
import "./Search.scss";

function Search() {
  useAnalytic("Search Page Visit");

  const [search, setSearch] = useState("");

  const [searchResultsPage, setSearchResultsPage] = useState(0);
  const { loading, results: searchResults, totalResultsCount } = useSearch(searchResultsPage, search);
  const totalResults = searchResults?.length ? searchResults.length : 0;

  const agentResults = totalResults ? (
    (searchResults || []).map(agent => <AgentResult agent={agent} key={agent.id} />)
  ) : (search === '')
        ?  (
            <div className="results-zero-state">Enter a search term to get started!</div>
            )
        :  loading
            ?  <div/>
            :  (
                  <div className="results-zero-state">No results found</div>
               );

  const handleLoadMore = () => {
    setSearchResultsPage(searchResultsPage + 1);
  };

  const loadMore =
    (!loading && (searchResults.length > 0) && (searchResults.length < totalResultsCount)) &&
      (
        <div className="cta-row">
          <div className="cta-button load-more-button" label="Load More" onClick={() => handleLoadMore()}>
            LOAD MORE
          </div>
        </div>
      );

  return (
    <div className="search">
      <Header showHomeButton={true} />
      <div className="hero">
        <div className="hero-title">Search Agents</div>
        <div className="cta-row">
          <SearchInput value={search} onChange={setSearch} />
        </div>
      </div>
      <div className="content-wrapper">
        <div className="content-title">Results {totalResults ? `(${totalResultsCount})` : ""}</div>
        <div className="agent-results">{agentResults}</div>
        {loading && (<div className="loader" />)}
        <div className="load-more">{loadMore}</div>
      </div>
      <Footer />
    </div>
  );
}

export default Search;
