import { useState, useEffect, useRef } from "react";
import axios from "axios";

function reduceReviewRowsIntoAgent(reviewRows) {
  if (reviewRows.length === 0) {
    return null;
  }
  const agent = reviewRows.reduce((acc, next) => {
    const { agentId, city, email, firstName, id, lastName, phoneNumber, rating, review, reviewDate, state, zip } = next;
    if (!acc.id) {
      acc = {
        id: agentId,
        firstName,
        lastName,
        email,
        phoneNumber,
        reviews: [
          {
            id,
            reviewDate: new Date(reviewDate),
            rating,
            review,
            state,
            city,
            zip
          }
        ]
      };
    } else {
      acc.reviews.push({
        id,
        reviewDate: new Date(reviewDate),
        rating,
        review,
        state,
        city,
        zip
      });
    }
    return acc;
  }, {});

  const { reviews } = agent;
  const ratingsTotal = reviews.reduce((acc, next) => acc + next.rating, 0);
  agent.averageRating = ratingsTotal / reviews.length;
  agent.state = Array.from(new Set(reviews.map(r => r.state))).join(", ");
  agent.city = Array.from(new Set(reviews.map(r => r.city))).join(", ");
  agent.zip = Array.from(new Set(reviews.map(r => r.zip))).join(", ");
  agent.reviews = agent.reviews.sort((a, b) => b.reviewDate - a.reviewDate);

  return agent;
}

function getAgentFromSecondEndpoint(endpointData) {
  const newAgent = endpointData[0];
  return newAgent
}

let inFlight = {};
let cache = {};

export default function useAgent(agentId, refreshId) {
  const localRefreshId = useRef();
  const [agent, setAgent] = useState({});

  useEffect(() => {
    if (agentId) {
      if (refreshId !== localRefreshId || (!cache[agentId] && !inFlight[agentId])) {
        (async () => {
          try {
            inFlight[agentId] = true;
            const { data } = await axios.get(
              `https://1pi0vb2rii.execute-api.us-east-1.amazonaws.com/dev/v1/reviews?agentId=${agentId}`
            );
            let agentWithReviews = reduceReviewRowsIntoAgent(data);
            if (agentWithReviews === null) {
              const { data } = await axios.get(
                `https://1pi0vb2rii.execute-api.us-east-1.amazonaws.com/dev/v1/agents?q=${agentId}`
              );
              console.log("MORE DATA", data);
                agentWithReviews = getAgentFromSecondEndpoint(data);
            }


            inFlight[agentId] = false;
            cache[agentId] = agentWithReviews;
            setAgent(agentWithReviews);
            localRefreshId.current = refreshId;
          } catch (err) {
            inFlight[agentId] = false;
            console.warn(err);
          }
        })();
      } else {
        setAgent(cache[agentId]);
      }
    }
  }, [agentId, refreshId]);

  return agent;
}
