import React from "react";
import "./FormInput.scss";

function FormInput({ type = "input", label, placeholder, value, onChange, disabled, optional }) {
  const updateValue = e => onChange(e.target.value);
  const optionalLabel = optional ? <i> - optional</i> : "";
  const fieldLabel = label ? <div className="form-input-label">{label}{optionalLabel}</div> : null;
  const field =
    type === "input" ? (
      <input
        className="form-input"
        placeholder={placeholder || ""}
        value={value || ""}
        onChange={updateValue}
        disabled={disabled}
      />
    ) : (
      <textarea
        className="form-input"
        placeholder={placeholder || ""}
        value={value || ""}
        onChange={updateValue}
        disabled={disabled}
      />
    );
  return (
    <div className="form-input-wrapper">
      {fieldLabel}
      {field}
    </div>
  );
}

export default FormInput;
