import { useEffect } from "react";
import axios from "axios";
import useDetailPageReviewsEnabled from "./useDetailPageReviewsEnabled";

export default function useAnalytic(type) {
  const detailPageReviewsEnabled = useDetailPageReviewsEnabled();
  useEffect(() => {
    (async () => {
      try {
        await axios.post(`https://1pi0vb2rii.execute-api.us-east-1.amazonaws.com/dev/v1/analytics`, {
          type,
          detailPageReviewsEnabled: detailPageReviewsEnabled == null ? true : detailPageReviewsEnabled
        });
      } catch (err) {
        console.warn(err);
      }
    })();
  }, []); // eslint-disable-line
}
